import { ComponentsData } from './ComponentsData'

export class SmsComponentsData extends ComponentsData {
  availableAttachments = []
  availableForms = []
  availableUrls = []
  certifiedSmsCost = null
  countries = {}
  countriesWithPhonePrefix = []
  defaultTimezone = ''
  groups = []
  lastSms = []
  bookmarks = []
  maxMessageChunks = null
  previousSenders = []
  sendingStatuses= {}
  timezones = {}
  twoWayDedicatedSenders = []
  twoWaySenders = []
  userCountryIso = null
  selectComponentMaxGroups = null

  constructor (response) {
    super()
    if (response) {
      this.countries = response.countries
      this.countriesWithPhonePrefix = response.countriesWithPhonePrefix
      this.defaultTimezone = response.defaultTimezone
      this.groups = response.groups
      this.timezones = response.timezones
      this.availableAttachments = response.availableAttachments
      this.availableForms = response.availableForms
      this.availableUrls = response.availableUrls
      this.certifiedSmsCost = response.certifiedSmsCost
      this.lastSms = response.lastSms
      this.bookmarks = response.bookmarks
      this.maxMessageChunks = response.maxMessageChunks
      this.previousSenders = response.previousSenders
      this.sendingStatuses = response.sendingStatuses
      this.twoWayDedicatedSenders = response.twoWayDedicatedSenders
      this.twoWaySenders = response.twoWaySenders
      this.userCountryIso = response.userCountryIso
      this.verifiedSenders = response.verifiedSenders
      this.selectComponentMaxGroups = response.selectComponentMaxGroups
    }
  }
}
